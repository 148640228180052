<template>
  <v-container class="ml-5 mr-5 mt-10 secondary">
    <v-row
      class="ml-5 pt-3"
      v-if="showtop"
    >
      <v-tooltip
        color="success"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            text
            color="success"
            @click="selectItem('title', 'Enter any part of title')"
            v-on="on"
            slot="activator"
          >
            <v-icon
              small
              left
            >search</v-icon>
            <span class="accent--text text-lowercase text-h6">Title</span>
          </v-btn>
        </template>
        <span>Search by Title</span>
      </v-tooltip>
      <v-tooltip
        color="success"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            text
            color="success"
            @click="selectItem('date', 'Enter yyyy-mm-dd and # of days')"
            v-on="on"
            slot="activator"
          >
            <v-icon
              small
              left
            >search</v-icon>
            <span class="accent--text text-lowercase text-h6">Date</span>
          </v-btn>
        </template>
        <span>Search by Date</span>
      </v-tooltip>
      <v-tooltip
        color="success"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            text
            color="success"
            @click="bldSlides()"
            v-on="on"
            slot="activator"
          >
            <v-icon
              small
              left
            >mdi-image-multiple</v-icon>
            <span class="accent--text text-lowercase text-h6">Slides</span>
          </v-btn>
        </template>
        <span>Build Slides</span>
      </v-tooltip>
      <v-tooltip
        color="success"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            text
            color="success"
            @click="addDoc()"
            v-on="on"
            slot="activator"
          >
            <v-icon
              small
              left
            >add_box</v-icon>
            <span class="accent--text text-lowercase text-h6">Add new photo</span>
          </v-btn>
        </template>
        <span>Add new Photo</span>
      </v-tooltip>
      <p
        v-if="nores != 'No Results'"
        class="title font-weight-black accent--text"
      >
        Total Photos: {{ total }}
      </p>
      <v-tooltip
        color="success"
        top
      >
        <template v-slot:activator="{ on }">
          <p class="ml-5">
            <span
              @click="serOff()"
              v-on="on"
              class="accent--text searchbar"
            >Search: </span>
            <span class="black-text font-weight-bold">{{
          search.substr(0, 50)
        }}</span>
          </p>
        </template>
        <span>Click to Turn Off Search</span>
      </v-tooltip>
    </v-row>
    <v-col
      v-if="editdoc || adddoc"
      xs12
      sm3
      md8
      class="mb-4"
    >
      <v-card class="primary">
        <v-card-title v-if="editdoc">
          <h2 class="accent--text">Edit Photo</h2>
        </v-card-title>
        <v-card-title v-else-if="adddoc">
          <h2 class="accent--text">Add New Photo</h2>
        </v-card-title>
        <v-card-text>
          <v-form
            class="px-3"
            ref="form"
            @submit.prevent="onSubmit"
          >
            <v-text-field
              color="accent"
              class="item-title"
              label="Title"
              v-model.lazy="curdoc.title"
            ></v-text-field>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  color="accent"
                  class="item-title"
                  label="Date"
                  clearable
                  v-on="on"
                  v-model.lazy="curdoc.date"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="curdoc.date"
                @change="menu = false"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              color="accent"
              class="item-title"
              label="Filename"
              type="text"
              readonly
              v-model.lazy="curdoc.pic"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              text
              @click="submit('E')"
              class="success mx-0 mt-3 mr-3"
            >Save Title</v-btn>
            <v-btn
              v-if="editdoc"
              type="submit"
              text
              @click="submit('A')"
              class="success mx-0 mt-3 mr-3"
            >Add New Title</v-btn>
            <v-btn
              text
              @click="
                editdoc = false;
                adddoc = false;
                showtop = true;
                dispdoc = false;
                curdoc = [];
              "
              class="success mx-0 mt-3"
            >Cancel</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-row v-if="upimg">
      <v-col>
        <input
          class="ml-3 subtitle-1 upbutton"
          type="file"
          id="file"
          ref="file"
          v-on:change="onChangeFileUpload()"
        />
        <v-btn
          top
          color="amber"
          class="subtitle-1 upbutton"
          v-on:click="submitForm()"
        >Upload</v-btn>
        <v-btn
          top
          color="amber"
          class="subtitle-1 ml-2"
          v-on:click="upimg = false, progress = false"
        >Cancel</v-btn>
        <v-progress-circular
          v-if="progress"
          indeterminate
          size="64"
          color="blue"
        >
          Loading...
        </v-progress-circular>
      </v-col>
    </v-row>
    <div
      :key="refresh"
      class="tablediv"
    >
      <table
        class="mytable"
        v-if="!dispdoc && !(adddoc || editdoc)"
      >
        <tbody>
          <tr>
            <th
              @click.exact="sortBy('title')"
              @click.shift.exact="sortByD('title')"
              class="title accent--text font-weight-bold"
            >
              Title
            </th>
            <th
              @click.exact="sortBy('date')"
              @click.shift.exact="sortByD('date')"
              class="title accent--text font-weight-bold"
            >
              Date
            </th>
            <th class="title accent--text font-weight-bold buttons">Actions</th>
          </tr>

          <tr
            v-for="project in projects"
            :key="project.id"
            row
            wrap
            :class="`pa-2 project ${project.status}`"
          >
            <td
              class="item-title"
              @click="editTxt(project.id)"
              v-html="project.title"
            ></td>
            <td
              class="item-title"
              @click="editTxt(project.id)"
            >
              {{ project.date }}
            </td>
            <td
              v-if="nores != 'No Results'"
              class="buttons"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    v-on="on"
                    class="mr-1 material-icons md-48"
                    @click="editDoc(project.id)"
                  >edit</v-icon>
                </template>
                <span>Edit Photo</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="my-2 material-icons md-48"
                    v-bind:class="project.pic ? 'pic' : 'nopic'"
                    @click="loadImg(project.id)"
                  >image</v-icon>
                </template>
                <span>Add Photo</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="my-2 material-icons md-48"
                    @click="delDoc(project.id, project.pic)"
                  >delete</v-icon>
                </template>
                <span>Delete Photo</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-dialog
      v-model="dialog"
      width="55%"
    >
      <v-card
        row
        flat
        class="primary"
      >
        <v-card-text class="mt-0 pt-0">
          <v-row class="primary mt-2 ml-5 mr-5">
            <v-col
              sm="10"
              md="11"
              lg="10"
              xl="7"
            >
              <h1
                class="success--text"
                v-html="curdoc.title"
              ></h1>
            </v-col>
            <v-col
              sm="2"
              md="1"
              lg="1"
              xl="1"
            >
              <v-btn
                large
                text
                color="accent"
                @click="dialog = false"
              >
                <v-icon
                  color="success"
                  large
                  left
                >close</v-icon>
                <span class="success--text text-h6 text-lowercase">Close</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="primary ml-5 mr-5">
            <v-col
              class="ck-content"
              sm="12"
              md="12"
              lg="10"
              xl="12"
            >
              <hr class="secondary thick" />
              <div class="img-box">
                <v-img
                  :src="curdoc.pic"
                  ratio="4/3"
                  class="mb-5"
                  sizes="80%"
                ></v-img>
              </div>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { shared } from "../mixins/shared";
import store from "../store/store.js";

export default {
  mixins: [shared],
  props: {
    tid: {
      type: String,
      required: true,
      default: "photos",
    },
  },
  data() {
    return {
      progress: false,
      dialog: false,
      curid: 0,
      file: [],
      total: 0,
      refresh: 0,
      projects: [],
      nores: "",
      editdoc: false,
      adddoc: false,
      dispdoc: false,
      showtop: true,
      upimg: false,
      addimage: false,
      curdoc: [],
      curid: 0,
      menu: false,
      menu2: false,
      search: store.photos_search,
      fld: store.photos_fld,
      picurl: "https://vsgpartners.com/images/",
      url: "https://vsgpartners.com/data.php",
      sliderValue: 0,
    };
  },
  methods: {
    submit() {
      if (this.curdoc.title) {
        var url = this.url;
        var action;
        if (this.editdoc) {
          action = "submitp";
        } else {
          action = "addp";
        }
        var formdata = {
          tid: this.tid,
          action: action,
          id: this.curdoc.id,
          title: this.curdoc.title,
          date: this.curdoc.date,
        };
        this.axios
          .post(url, formdata, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then(() => {
            this.getDocs();
          })
          .catch((error) => alert(error));
        this.editdoc = false;
        this.adddoc = false;
        this.curdoc = [];
        this.projects = [];
        this.dispdoc = false;
        this.showtop = true;
      }
    },
    loadImg(id) {
      this.curid = id;
      this.upimg = true;
    },
    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    submitFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      this.axios
        .post("https://vsgpartners.com/api.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (data) {
          console.log(data.data);
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
    async submitForm() {
      this.progress = true;
      var check = document.getElementById("file");
      if (check.value == "") {
        alert("Must choose a file");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.file);
      await this.axios
        .post("https://vsgpartners.com/api.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.storeImg(this.file.name);
      this.upimg = false;
      alert("Image Uploaded");
      this.progress = false;
      this.getDocs();
      this.forceRerender();
    },
    storeImg(img) {
      var url = "https://vsgpartners.com/data.php";
      var formdata = {
        action: "uploado",
        id: this.curid,
        img: img,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then()
        .catch((error) => alert(error));
    },
    async getDocs() {
      var url = this.url;
      var formdata = {
        action: "displayp",
        fld: this.fld,
        tid: this.tid,
        search: this.search,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          const data = res.data;
          this.projects = data;
          this.total = this.projects.length;
          this.nores = this.projects[0].title;
        })
        .catch((error) => alert(error));
    },
    fileChanged() {
      this.curdoc.pic = this.file.name;
    },
    bldSlides() {
      if (this.projects.length > 150) {
        alert("Selection too Large");
        exit;
      }
      if (this.nores == "No Results") {
        alert("No Selection");
        exit;
      }
      var slides = this.projects.map((data) => [
        data.pic,
        data.imgurl,
        data.title,
      ]);
      var url = this.url;
      var formdata = {
        action: "bldslides",
        search: this.search,
        slides: slides,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then(() => {
          alert("Slides Created");
        })
        .catch((error) => alert(error));
    },
    editDoc(id) {
      var url = this.url;
      var formdata = {
        action: "editp",
        tid: this.tid,
        id: id,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          const data = res.data;
          this.curdoc = data[0];
          this.file = [];
          this.editdoc = true;
          this.showtop = false;
          this.dispdoc = false;
        })
        .catch((error) => alert(error));
    },
    editTxt(id) {
      for (var i = 0; i < this.projects.length; i++) {
        if (this.projects[i].id === id) {
          this.curdoc.pic = this.picurl + this.projects[i].pic;
          this.curdoc.title = this.projects[i].title;
          this.dialog = true;
        }
      }
    },
    addDoc() {
      this.adddoc = true;
      this.showtop = false;
      this.dispdoc = false;
      this.curdoc = [];
      this.file = [];
    },
    selectItem(which, cue) {
      this.fld = which;
      this.search = prompt(cue);
      store.photos_search = this.search;
      store.photos_fld = this.fld;
      this.saveSearch(this.search, "photos", this.fld);
      this.getDocs();
    },
    serOff() {
      this.search = "";
      this.fld = "";
      store.photos_search = this.search;
      store.photos_fld = this.fld;
      this.saveSearch(this.search, "photos", this.fld);
      this.getDocs();
    },
    delDoc(id, filename) {
      console.log(filename);
      if (confirm("Do you really want to delete?")) {
        var url = this.url;
        var db = store.durl;
        var formdata = {
          action: "delp",
          filename: "/images/" + filename,
          tid: this.tid,
          id: id,
          db: db,
        };
        this.axios
          .post(url, formdata, {
            headers: {
              "Content-Type": "text/plain",
            },
          })
          .then(() => {
            this.getDocs();
          })
          .catch((error) => alert(error));
      }
    },
    saveSearch(search, sdb, fld) {
      var url = this.url;
      var formdata = {
        action: "search-s",
        search: search,
        tid: "startup",
        sdb: sdb,
        fld: fld,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then(() => {})
        .catch((error) => alert(error));
    },
  },
  async created() {
    var url = this.url;
    var formdata = {
      action: "search-g",
      tid: "startup",
    };
    await this.axios
      .post(url, formdata, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((res) => {
        const data = res.data;
        store.photos_search = data[0].search;
        store.photos_fld = data[0].fld;
        this.search = store.photos_search;
        this.fld = store.photos_fld;
      })
      .catch((error) => alert(error));
    var url = this.url;
    var formdata = {
      action: "displayp",
      search: store.photos_search,
      fld: store.photos_fld,
      tid: this.tid,
    };
    this.axios
      .post(url, formdata, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((res) => {
        const data = res.data;
        this.projects = data;
        this.total = this.projects.length;
        this.nores = this.projects[0].title;
      })
      .catch((error) => alert(error));
  },
};
</script>

<style scoped>
.nopic {
  color: red;
}
.pic {
  color: green;
}
.dial {
  overflow: hidden !important;
}
.img-click2 {
  margin-left: 15%;
}
.img-click2:hover {
  transform: scale(4);
  z-index: 10;
}
.upbutton:hover {
  color: red;
}
.upbutton:active {
  color: blue;
}
th,
td {
  padding: 15px;
}
td:hover {
  white-space: normal;
  width: auto;
}
p,
li {
  font-size: 1.2rem;
  letter-spacing: 1px;
  line-height: 150%;
}
p {
  clear: both;
}
.item {
  width: 45%;
}

.dates {
  text-align: center;
}

.plays {
  text-align: center;
}

.fstop {
  text-align: left;
  width: 160px;
}

.tracks {
  text-align: center;
}

.buttons {
  text-align: left;
}

@media only screen and (max-width: 1600px) {
  table {
    width: 100%;
  }
}
</style>
